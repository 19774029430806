import {request} from "../../../util/Request";
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'order_id', label: this.$t('title.orderId'), show: true},
                    {id: 'load_status', label: this.$t('title.status'), show: true},
                    {id: 'vin', label: this.$t('title.vinNumber'), show: true},
                    {id: 'from_location', label: this.$t('title.fromLocation'), show: true},
                    {id: 'to_location', label: this.$t('title.toLocation'), show: true},
                    {id: 'client', label: this.$t('title.client'), show: true},
                    {id: 'dealer', label: this.$t('title.dealer'), show: true},
                    {id: 'manufacturer', label: this.$t('title.manufacturer'), show: true},
                    {id: 'brand', label: this.$t('title.brand'), show: true},
                    {id: 'model', label: this.$t('title.model'), show: true},
                    {id: 'invoice_number_importer', label: this.$t('title.invoiceNumberImporter'), show: true},
                    {id: 'call_off_date_importer', label: this.$t('title.callOffDateImporter'), show: true},
                    {id: 'supplier_carrier_id', label: this.$t('title.supplierCarrier'), show: true},
                    {id: 'supplier_compound_id', label: this.$t('title.supplierCompound'), show: true},
                    {id: 'outbound_supplier_carrier_id', label: this.$t('title.destinationLocation'), show: true},
                    {id: 'dealer_location_id', label: this.$t('title.destinationLocation'), show: true},
                    {id: 'client_tr_at', label: this.$t('title.clientTransportRequest'), show: true},
                    {id: 'client_tr_accept_at', label: this.$t('title.clientTransportRequestAcceptAt'), show: true},
                    {id: 'comments', label: this.$t('title.comments'), show: true},
                    {id: 'attachments', label: this.$t('title.attachments'), show: true},
                ],
                headers: {
                    [this.$t('column.orderId')]: 'order_id',
                    [this.$t('column.status')]: 'load_status',
                    [this.$t('column.vin')]: 'vin',
                    [this.$t('column.fromLocation')]: 'from_location',
                    [this.$t('column.toLocation')]: 'to_location',
                    [this.$t('column.client')]: 'client',
                    [this.$t('column.dealer')]: 'dealer',
                    [this.$t('column.manufacturer')]: 'manufacturer',
                    [this.$t('column.brand')]: 'brand',
                    [this.$t('column.model')]: 'model',
                    [this.$t('column.invoiceNumberImporter')]: 'invoice_number_importer',
                    [this.$t('column.callOffDateImporter')]: 'call_off_date_importer',
                    [this.$t('column.supplierCarrier')]: 'supplier_carrier_id',
                    [this.$t('column.supplierCompound')]: 'supplier_compound_id',
                    [this.$t('column.outboundSupplierCarrier')]: 'outbound_supplier_carrier_id',
                    [this.$t('column.destinationLocation')]: 'dealer_location_id',
                    [this.$t('column.clientTransportRequest')]: 'client_tr_at',
                    [this.$t('column.clientTransportRequestAcceptAt')]: 'client_tr_accept_at',
                    [this.$t('column.comment')]: 'comments',
                    [this.$t('column.attachments')]: 'attachments',
                }
            },
        }
    },
    methods: {
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'archives/orders/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response

            const rows = _.map(data, (item, index) => _.pick({
                order_id: this.$global.val(item.order_id),
                load_status: this.$global.val(item._load_status),
                vin: this.$global.val(item.vin_number),
                from_location: this.$global.val((item.from_location || {}).label),
                to_location: this.$global.val((item.to_location || {}).label),
                client: this.$global.val((item.client || {}).company_name),
                dealer: this.$global.val((item.dealer || {}).name),
                manufacturer: this.$global.val((item.manufacturer || {}).name),
                brand: this.$global.val(item.brand.title),
                model: this.$global.val(item.model.title),
                invoice_number_importer: this.$global.val(item.invoice_number_importer),
                call_off_date_importer: this.$global.val(item.call_off_date_importer),
                supplier_carrier_id: this.$global.val((item.supplier_carrier || {}).name),
                supplier_compound_id: this.$global.val((item.supplier_compound || {}).name),
                outbound_supplier_carrier_id: this.$global.val((item.outbound_supplier_carrier || {}).name),
                dealer_location_id: this.$global.val((item.dealer_location || {}).label),
                client_tr_at: this.$global.val(item.client_tr_at),
                client_tr_accept_at: this.$global.val(item.client_tr_accept_at),
                comments: this.$global.val(item.comments),
                attachments: this.$global.val(item.damage_histories) ? this.getDamageDocuments(this.$global.val(item.damage_histories)) : []
            }, columns))
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
        getDamageDocuments(data) {
            const order_damage_attachments = _.map(data, (i) => i.order_damage_attachments)
            const attachments = _.map(order_damage_attachments, (i) => _.map(i, (d) => d.document.download_url))
            return attachments ? attachments.join(",") : []
        }
    }
}
