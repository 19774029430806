<template>
    <div>
        <div v-if="detailLoad">
            <div class="row">
                <div class="col-sm-6">
                    <p class="text-dark font-size-48 font-weight-bold mb-0">#{{detailLoad.order_id}}</p>
                    <h6 class="mt-1 mb-3">
                        <ol-status :status="detailLoad.load_status"></ol-status>
                        <span class="badge badge-default text-dark ml-2"> {{$global.utcDateToLocalDate(detailLoad.created_at) }}</span>
                    </h6>
                </div>
                <div class="col-sm-6 text-right">
                    <a href="javascript: void(0);" class="btn btn-outline-primary ml-1" @click="handleCancelClick()"> <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}} </a>
                </div>
            </div>
            <b-row>
                <b-col sm="12">
                    <b-row>
                        <b-col md="8" lg="8" sm="12">
                            <b-row>
                                <b-col md="4" lg="4" sm="12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <div class="mr-auto"><p class="text-uppercase text-dark font-weight-bold mb-1"> {{$t('title.client')}} </p>
                                                    <p class="text-gray-5 mb-0">{{detailLoad.client.company_name}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="4" lg="4" sm="12" v-if="detailLoad.dealer">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <div class="mr-auto"><p class="text-uppercase text-dark font-weight-bold mb-1"> {{$t('title.dealer')}} </p>
                                                    <p class="text-gray-5 mb-0">{{(detailLoad.dealer || {}).name}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="4" lg="4" sm="12" v-if="detailLoad.manufacturer">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex flex-wrap align-items-center">
                                                <div class="mr-auto"><p class="text-uppercase text-dark font-weight-bold mb-1"> {{$t('title.manufacturer')}} </p>
                                                    <p class="text-gray-5 mb-0">{{(detailLoad.manufacturer || {}).name}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="4" lg="4" sm="12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="my-1 mr-4 font-size-36 flex-shrink-0"><i class="fe fe-truck"></i></div>
                                        <div>
                                            <div class="font-size-18 font-weight-bold text-dark"> {{(detailLoad.brand || {}).title}}</div>
                                            <div class="font-size-12 text-uppercase"> {{(detailLoad.model || {}).title}}</div>
                                            <div class="font-size-12 text-uppercase">  {{(detailLoad.model || {}).version_code}} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col sm="12">
                    <b-row>
                        <b-col sm="6" class="mb-4">
                            <ul class="timeline">
                                <template v-for="(timeline, index) in detailLoad.order_operations">
                                    <li v-if="timeline.operation === 'inbound_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#"> {{ $t('title.inboundLoad') }}# {{timeline.inbound_load.load_number}}</a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.inbound_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.inbound_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCompound')}}</td>
                                                                <td class="text-right text-muted">{{timeline.inbound_load.supplier_compound.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.inbound_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.inbound_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{timeline.inbound_load.driver.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.inbound_load.transport_vehicle">
                                                                        {{timeline.inbound_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.inbound_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.inbound_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.gateIn')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.gate_in) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.gateOut')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.gate_out) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.freeStorageDays')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{ timeline.free_storage_days }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.storageDays')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{ timeline.storage_days }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'cc_inbound_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        CC {{ $t('title.inboundLoad') }}# {{timeline.cc_inbound_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.cc_inbound_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.cc_inbound_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCompound')}}</td>
                                                                <td class="text-right text-muted">{{timeline.cc_inbound_load.supplier_compound.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.cc_inbound_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.cc_inbound_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{timeline.cc_inbound_load.driver.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.cc_inbound_load.transport_vehicle">
                                                                        {{timeline.cc_inbound_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.cc_inbound_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.cc_inbound_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.gateIn')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.gate_in) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.gateOut')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.gate_out) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.freeStorageDays')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.free_storage_days) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.storageDays')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{$global.utcDateToLocalDate(timeline.storage_days) }}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'spot_inbound_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        SPOT {{ $t('title.inboundLoad') }}# {{timeline.spot_inbound_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.spot_inbound_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                    <td class="text-right text-muted">{{timeline.spot_inbound_load.supplier_carrier.name}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.supplierCompound')}}</td>
                                                                    <td class="text-right text-muted">{{timeline.spot_inbound_load.supplier_compound.name}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                    <td class="text-right text-muted"><print-ad titled="1" :item="timeline.spot_inbound_load.from_location"></print-ad></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                    <td class="text-right text-muted"><print-ad titled="1" :item="timeline.spot_inbound_load.to_location"></print-ad></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                    <td class="text-right text-muted">{{timeline.spot_inbound_load.driver.name}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                    <td class="text-right text-muted">
                                                                        <div v-if="timeline.spot_inbound_load.transport_vehicle">
                                                                            {{timeline.spot_inbound_load.transport_vehicle.truck_plate_number}}
                                                                        </div>
                                                                        <div v-if="timeline.spot_inbound_load.transport_vehicle.trailer_plate_number">
                                                                            {{timeline.spot_inbound_load.transport_vehicle.trailer_plate_number}}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.gateIn')}}</td>
                                                                    <td class="text-right text-muted">
                                                                        {{$global.utcDateToLocalDate(timeline.gate_in) }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.gateOut')}}</td>
                                                                    <td class="text-right text-muted">
                                                                        {{$global.utcDateToLocalDate(timeline.gate_out) }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.freeStorageDays')}}</td>
                                                                    <td class="text-right text-muted">
                                                                        {{$global.utcDateToLocalDate(timeline.free_storage_days) }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="pl-0 font-weight-bold">{{$t('title.storageDays')}}</td>
                                                                    <td class="text-right text-muted">
                                                                        {{$global.utcDateToLocalDate(timeline.storage_days) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'outbound_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        {{ $t('title.outboundLoad') }}# {{timeline.outbound_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.outbound_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.outbound_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCompound')}}</td>
                                                                <td class="text-right text-muted">{{timeline.outbound_load.supplier_compound.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.outbound_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.outbound_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{timeline.outbound_load.driver.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.outbound_load.transport_vehicle">
                                                                        {{timeline.outbound_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.outbound_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.outbound_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'reexport_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        {{ $t('title.reexport') }}# {{timeline.reexport_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.reexport_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.reexport_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCompound')}}</td>
                                                                <td class="text-right text-muted">{{timeline.reexport_load.supplier_compound.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.reexport_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.reexport_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{timeline.reexport_load.driver.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.reexport_load.transport_vehicle">
                                                                        {{timeline.reexport_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.reexport_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.reexport_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.price')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{timeline.reexport_load.price}} ({{timeline.reexport_load.currency}})
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'dealer_to_dealer_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        {{ $t('title.dealerToDealer') }}# {{timeline.dtd_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.dtd_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.dtd_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.dealer')}}</td>
                                                                <td class="text-right text-muted">{{timeline.dtd_load.dealer.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.dtd_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.dtd_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{((timeline.dtd_load || {}).driver || {}).name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.dtd_load.transport_vehicle">
                                                                        {{timeline.dtd_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.dtd_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.dtd_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.price')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{timeline.dtd_load.price}} ({{timeline.dtd_load.currency}})
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                    <li v-if="timeline.operation === 'spot_load'">
                                        <h4 class="m-0">
                                            <div class="clearfix">
                                                <div class="pull-left">
                                                    <a target="_blank" href="#">
                                                        {{ $t('title.spotLoad') }}# {{timeline.spot_load.load_number}}
                                                    </a>
                                                </div>
                                                <div class="pull-right font-size-16">
                                                    {{$global.utcDateToLocalDate(timeline.spot_load.created_at) }}
                                                </div>
                                            </div>
                                        </h4>
                                        <b-row>
                                            <b-col sm="12">
                                                <b-card class="mt-3">
                                                    <div class="table-responsive">
                                                        <table class="table table-borderless text-gray-6 mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.supplierCarrier')}}</td>
                                                                <td class="text-right text-muted">{{timeline.spot_load.supplier_carrier.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.client')}}</td>
                                                                <td class="text-right text-muted">{{timeline.spot_load.client ? timeline.spot_load.client.company_name : ""}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.fromLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.spot_load.from_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.toLocation')}}</td>
                                                                <td class="text-right text-muted"><print-ad titled="1" :item="timeline.spot_load.to_location"></print-ad></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.driver')}}</td>
                                                                <td class="text-right text-muted">{{timeline.spot_load.driver.name}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.transportVehicle')}}</td>
                                                                <td class="text-right text-muted">
                                                                    <div v-if="timeline.spot_load.transport_vehicle">
                                                                        {{timeline.spot_load.transport_vehicle.truck_plate_number}}
                                                                    </div>
                                                                    <div v-if="timeline.spot_load.transport_vehicle.trailer_plate_number">
                                                                        {{timeline.spot_load.transport_vehicle.trailer_plate_number}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-0 font-weight-bold">{{$t('title.price')}}</td>
                                                                <td class="text-right text-muted">
                                                                    {{timeline.spot_load.price}} ({{timeline.spot_load.currency}})
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-card>
                                            </b-col>
                                        </b-row>
                                    </li>
                                </template>
                            </ul>
                        </b-col>
                        <b-col sm="6" class="mb-4">
                            <div class="card p-0">
                                <div class="box border rounded p-4">
                                    <b-tabs content-class="mt-3" fill>
                                        <b-tab :title="$t('title.additionalServices')" active>
                                            <div class="mt-3" v-if="detailLoad.additional_services">
                                                <div class="clearfix">
                                                    <table class="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{{$t('column.additionalService')}}</th>
                                                                <th>{{$t('column.description')}}</th>
                                                                <th>{{$t('column.compoundNote')}}</th>
                                                                <th>ETR</th>
                                                                <th>{{$t('column.status')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(service,index) in detailLoad.additional_services">
                                                            <td>{{index + 1}}</td>
                                                            <td>{{service.additional_service.title}}</td>
                                                            <td>{{service.description}}</td>
                                                            <td>{{service.compound_notes}}</td>
                                                            <td>{{service.estimated_time_repair ?
                                                                $global.dateFormat(service.estimated_time_repair): ''}}
                                                            </td>
                                                            <td>
                                                                <span v-if="service.is_rejected">
                                                                    {{ $t('title.rejected') }}
                                                                </span>
                                                                <span v-else>
                                                                    {{(!(!service.marked_done_at)) ? $t('title.done') : $t('title.pending') }}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                        <tfoot
                                                            v-if="detailLoad.additional_services && detailLoad.additional_services.length == 0">
                                                        <tr>
                                                            <td colspan="6">{{$t('title.noDataAvailable')}}</td>
                                                        </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div><!--/.box-->
                                        </b-tab>
                                        <b-tab :title="$t('title.damageHistory')">
                                            <div class="mt-3" v-if="detailLoad.damage_histories">
                                                <div class="clearfix">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{{$t('column.creator')}}</th>
                                                                <th>ETR</th>
                                                                <th>{{$t('column.controllerName')}}</th>
                                                                <th>{{$t('column.comments')}}</th>
                                                                <th>{{$t('column.attachment')}}</th>
                                                                <th>{{$t('column.createdAt')}}</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(damage, index) in detailLoad.damage_histories">
                                                                <td>{{index + 1}}</td>
                                                                <td>{{damage.author}}</td>
                                                                <td>{{damage.etr ? $global.utcDateToLocalDate(damage.etr,
                                                                    'DD.MM.YYYY', 'YYYY-MM-DD'): ''}}
                                                                </td>
                                                                <td>{{damage.controller_name}}</td>
                                                                <td>{{damage.comments}}</td>
                                                                <td><a v-if="damage.order_damage_attachments.length" v-b-modal.files-attachments
                                                                       @click="getAttachmentItem(damage.order_damage_attachments,$t('title.damageHistoriesAttachment'))">
                                                                    <i class="fe fe-paperclip"></i>Attachments</a>
                                                                    <a v-else href="javascript:;">No Attachment</a>
                                                                </td>
                                                                <td>{{damage.created_at ?
                                                                    $global.utcDateToLocalDate(damage.created_at): ''}}
                                                                </td>

                                                            </tr>
                                                            </tbody>
                                                            <tfoot
                                                                v-if="detailLoad.damage_histories && detailLoad.damage_histories.length == 0">
                                                            <tr>
                                                                <td colspan="7">{{$t('title.noDataAvailable')}}</td>
                                                            </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div><!--/.box-->
                                        </b-tab>
                                        <b-tab :title="$t('title.orderDocuments')">
                                            <div class="mt-3" v-if="detailLoad.order_documents">
                                                <div class="clearfix">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{$t('column.title')}}</th>
                                                                    <th>{{$t('column.createdAt')}}</th>
                                                                    <th>{{$t('column.action')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(damage, index) in detailLoad.order_documents">
                                                                    <td>{{index + 1}}</td>
                                                                    <td>
                                                                        {{damage.title}}
                                                                        <div>{{damage.type}}</div>
                                                                        <div>{{damage.comments}}</div>
                                                                    </td>
                                                                    <td>{{damage.created_at ? $global.utcDateToLocalDate(damage.created_at): ''}}</td>
                                                                    <td>
                                                                        <a :href="damage.document.download_url" target="_blank">
                                                                            <i class="fe fe-download"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot v-if="detailLoad.order_documents && detailLoad.order_documents.length == 0">
                                                                <tr>
                                                                    <td colspan="7">{{$t('title.noDataAvailable')}}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div><!--/.box-->
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div><!--/.card-->
                            <div class="card p-0" v-if="detailLoad.load_status_histories">
                                <div class="box border rounded p-4">
                                    <h4>
                                        <span class="text-capitalize">{{$t('title.history')}}</span>
                                    </h4>
                                    <hr>
                                    <div class="clearfix">
                                        <table class="table table-striped">
                                            <thead>
                                            <tr>
                                                <th width="120">{{$t('title.status')}}</th>
                                                <th width="550">{{$t('title.comments')}}</th>
                                                <th width="200">{{$t('title.dateTime')}}</th>
                                                <th width="200">{{$t('title.updatedBy')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(history, index) in detailLoad.load_status_histories">
                                                <template v-if="history.status == 8">
                                                    <tr v-if="detailLoad.client_tr_accept_at">
                                                        <td>{{ $t('title.readyToBeLoaded') }}</td>
                                                        <td>
                                                            {{ (detailLoad.transport_request || {}).supplier_notes }}
                                                        </td>
                                                        <td>{{ $global.utcDateToLocalDate(detailLoad.client_tr_accept_at)}}</td>
                                                        <td>{{ (detailLoad.supplier_compound) ?
                                                            detailLoad.supplier_compound.name : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="detailLoad.client_tr_at">
                                                        <td>{{$t('title.deliveryRequest')}}</td>
                                                        <td>{{(detailLoad.transport_request || {}).client_notes}}</td>
                                                        <td>{{ $global.utcDateToLocalDate(detailLoad.client_tr_at) }}</td>
                                                        <td>{{ (detailLoad.client) ? detailLoad.client.company_name : ''
                                                            }}
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr>
                                                    <td>{{history._status}}</td>
                                                    <td>{{history.comments}}</td>
                                                    <td>{{ $global.utcDateToLocalDate(history.created_at) }}</td>
                                                    <td>{{history.author}}</td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table><!--/.table.table-striped -->
                                    </div><!--/.clearfix-->
                                </div><!--/.box-->
                            </div><!--/.card-->
                        </b-col>
                    </b-row>
                </b-col><!--/.col-->
            </b-row><!--/.row-->
        </div><!--/div-->
        <!--  attachment modal-->
        <b-modal id="files-attachments" ok-only :title="selectedAttachmentTitle" ok-title="Cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{$t('column.attachment')}}</th>
                        <th>{{$t('column.download')}}</th>
                        <th>{{$t('column.createdAt')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file,index) in selectedAttachment">
                        <td>{{index+1}}</td>
                        <td>{{file.title}}.{{file.document.extension}}</td>
                        <td>
                            <a :title="$t('button.download')"
                               v-b-tooltip.hover variant="outline-primary"
                               class="mb-1"
                               v-if="file.document && file.document.download_url"
                               :disabled="!(file.document && file.document.download_url)"
                               :href="(file.document ? file.document.download_url : '')"
                               target="_blank">
                                <i class="fe fe-download"></i>
                            </a>
                        </td>
                        <td>
                            {{file.document.created_at ? $global.utcDateToLocalDate(file.document.created_at):
                            ''}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import {request} from "../../../util/Request";
    import {mapState} from 'vuex'
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"

    export default {
        mixins: [ExcelExportMixin],
        components: {
            JsonExcel
        },
        props: ['id', 'handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                items: [],
                selectedAttachment:[],
                selectedAttachmentTitle:null,
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            getAttachmentItem(item,title) {
                this.selectedAttachmentTitle = title
                this.selectedAttachment = item
            },
            async getDetail(id) {
                await request({
                    url: `/archives/orders/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {
                        // console.log(error)
                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style>
    ul.timeline {
        list-style-type: none;
        position: relative;
    }
    ul.timeline:before {
        content: ' ';
        background: #d4d9df;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 2px;
        height: 100%;
        z-index: 400;
    }
    ul.timeline > li {
        margin: 20px 0;
        padding-left: 20px;
    }
    ul.timeline > li:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #22c0e8;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400;
    }
</style>
